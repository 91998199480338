import React, {useEffect, useState} from 'react';
import {Paper, Tooltip, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ImportContactsRoundedIcon from '@mui/icons-material/ImportContactsRounded';
import ScienceRoundedIcon from '@mui/icons-material/ScienceRounded';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import PublicIcon from '@mui/icons-material/Public';
import CloudIcon from '@mui/icons-material/Cloud';

import axios from "axios";
import CenterLoading from "../components/CenterLoading";
import IconButton from "@material-ui/core/IconButton";
import dayjs from "dayjs";
import 'dayjs/locale/de';
import localizedFormat  from 'dayjs/plugin/localizedFormat';




const Substitutions = () => {

    const [subData, setSubData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCached, setIsCached] = useState(true);
    const [error, setError] = useState(null);
    const [cachedTime, setCachedTime] = useState(null);

    const refreshData = () => {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/substitutions/all`)
            .then(({data, status}) => {
                if(status === 210) {
                    setIsCached(true);
                    dayjs.extend(localizedFormat);
                    dayjs.locale("de");
                    setCachedTime(dayjs(data.updated).format('L [um] LT'))
                }
                if(data.error) {
                    setError(data);
                } else {
                    setSubData(data.substitutions);
                    setIsCached(true);
                    dayjs.extend(localizedFormat);
                    dayjs.locale("de");
                    setCachedTime(dayjs(data.updated).format('L [um] LT'))
                }
                setIsLoading(false)

            })
    }

    useEffect(() => {
        refreshData();
    }, []);

    const getIcon = (icon) => {
        switch (icon) {
            case "book":
                return <ImportContactsRoundedIcon />;
            case "flask":
                return <ScienceRoundedIcon />;
            case "calculator":
                return <CalculateRoundedIcon />;
            case "globe":
                return <PublicIcon />;
            default:
                return <ImportContactsRoundedIcon />;
        }
    }

    return (
        <div>
            {
                isLoading && <Box sx={{height: "100vh"}}><CenterLoading /></Box>
            }
            {
                isCached && <Box>
                    <Tooltip disableFocusListener title={`Die Angaben sind zwischengespeichert. Die Speicherung erfolgte ${cachedTime}.`} placement={"right"}>
                        <IconButton>
                            <CloudIcon/>
                        </IconButton>
                    </Tooltip>

                </Box>
            }

                {
                    subData.map((d) => {
                        return (
                            <Box sx={{mt: 2, mb: 2}}>
                                <Typography variant={"h5"} sx={{mb: 1, fontWeight: "bold"}}>{d.name}</Typography>
                                {
                                    d.substitutions.map((s) => {
                                        return (
                                            <Paper variant="outlined" sx={{padding: 1, borderRadius: "35px", display: "flex", my: 2}}>
                                                <Box sx={{backgroundColor: s.color, color: "white", width: "50px", height: "50px", borderRadius: "50%", display: 'flex', justifyContent: "center", alignItems: "center"}}>
                                                    {getIcon(s.icon)}
                                                </Box>
                                                <Box ml={3} style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>

                                                    <Box>{s.subject}</Box>
                                                    <Box style={{fontSize: "13px"}}>{s.lesson}. Stunde {s.description}</Box>
                                                </Box>
                                            </Paper>
                                        )
                                    })
                                }

                            </Box>

                        )
                    })
                }



        </div>
    );
};

export default Substitutions;
