import {useAuth, useFirestore, useFirestoreDocData, useUser} from "reactfire";

function useProfile() {
    const {data: user} = useUser();
    const firestore = useFirestore();
    const auth = useAuth();
    const userDetailsRef = firestore
        .collection('users')
        .doc(user?.uid);

    let {data} = useFirestoreDocData(
        userDetailsRef
    );

    return data;
}

export default useProfile;
