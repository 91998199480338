const firebaseConfig = {
    apiKey: "AIzaSyBgH-2H0qSqqq9hmgdKbS0dglNZ9hhghTY",
    authDomain: "gg-go-v2.firebaseapp.com",
    projectId: "gg-go-v2",
    storageBucket: "gg-go-v2.appspot.com",
    messagingSenderId: "159399881916",
    appId: "1:159399881916:web:9501657c29be765c29b4b9",
    measurementId: "G-WN5QEKZHQ7"
}

export default firebaseConfig;