import * as React from 'react';
import {Suspense, useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import {Link, useLocation} from "react-router-dom";

import ListItemButton from "@material-ui/core/ListItemButton";
import Collapse from "@material-ui/core/Collapse";

import Avatar from "@material-ui/core/Avatar";
import {useSigninCheck} from "reactfire";
import Skeleton from "@material-ui/core/Skeleton";
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import {useHistory} from "react-router-dom";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Button from "@material-ui/core/Button";
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import TableChartRoundedIcon from '@mui/icons-material/TableChartRounded';
import TimetableSmall from "./TimetableSmall";
import CenterLoading from "./CenterLoading";


const drawerWidth = 275;

const menuItems = [

    {
        title: "Übersicht",
        icon: <DashboardRoundedIcon/>,
        location: '/'
    },
    {
        title: "Vertretungen",
        icon: <PeopleRoundedIcon/>,
        location: '/substitutions'
    },
    {
        title: "Stundenplan",
        icon: <TableChartRoundedIcon/>,
        location: '/timetable'
    },
    {
        title: "Einstellungen",
        icon: <SettingsRoundedIcon/>,
        location: '/settings',
        invisible: true
    },
    {
        // fix to show drawer icon but no title when profile page is open
        title: "",
        icon: <SettingsRoundedIcon/>,
        location: '/profile',
        invisible: true
    }
];

const ProfileButton = (props) => {
    const {data: signInCheckResult} = useSigninCheck();
    return (

        <ListItemLink primary={"Profil"}
                      secondary={signInCheckResult.signedIn ? (signInCheckResult.user.email ? signInCheckResult.user.email : signInCheckResult.user.phoneNumber) : "Nicht angemeldet"}
                      icon={<Avatar src={signInCheckResult.signedIn ? (signInCheckResult.user.email ? `https://avatars.dicebear.com/api/identicon/${signInCheckResult.user.email}.svg?b=%23f9f9f9` : `https://avatars.dicebear.com/api/identicon/${signInCheckResult.user.phoneNumber}.svg?b=%23f9f9f9`) : "#"}/>}
                      selected={props.location.pathname === "/profile"}
                      sx={{borderRadius: 1}}
                      to={"/profile"}
                      click={() => setTimeout(() => props.setMobileOpen(false), 150)}>
        </ListItemLink>
    )
}

const ProfileButtonFallback = (props) => {
    return (
        <ListItemLink primary={"Profil"}
                      secondary={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
                      icon={<Skeleton variant="circle" sx={{borderRadius: "50%"}}><Avatar/></Skeleton>}
                      selected={props.location.pathname === "/profile"}
                      sx={{borderRadius: 1}}
                      to={"/profile"}
                      click={() => setTimeout(() => props.setMobileOpen(false), 150)}>
        </ListItemLink>
    )
}

function NavBar(props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();
    const [greeting, setGreeting] = useState("");
    const history = useHistory()

    useEffect(() => {
        let hour = new Date().getHours();
        let status = (hour < 12) ? "Morgen 🌅" :
            ((hour <= 18 && hour >= 12) ? "Tag ☀" : "Abend 🌙");
        setGreeting(`Guten ${status}`)

    }, []);


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [moreOpen, setMoreOpen] = React.useState(false);

    const handleMoreClick = () => {
        setMoreOpen(!moreOpen);
    };


    const drawer = (
        <div>
            <Toolbar sx={{height: '90px'}}>
                <Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="div"
                        sx={{fontWeight: '900'}}
                    >
                        GG Go
                    </Typography>
                    <Typography>
                        {greeting}
                    </Typography>
                </Box>
            </Toolbar>
            <Divider/>
            <List sx={{padding: 1}}>
                {
                    menuItems.map((item) => {
                            if (item.invisible) {
                                return (<div />);
                            }
                            return (
                                <ListItemLink primary={item.title} icon={item.icon} key={item.title}
                                              selected={location.pathname === item.location} sx={{borderRadius: 1}}
                                              to={item.location} click={() => setTimeout(() => setMobileOpen(false), 150)}>
                                </ListItemLink>
                            )
                        }
                    )}
                <ListItemButton onClick={handleMoreClick} sx={{borderRadius: 1}}>
                    <ListItemIcon>
                        <MoreHorizRoundedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Mehr"/>
                    {moreOpen ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={moreOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>

                        <ListItemLink primary={"Einstellungen"} icon={<SettingsRoundedIcon/>}
                                      selected={location.pathname === "/settings"} sx={{borderRadius: 1}}
                                      to={"/settings"} click={() => setTimeout(() => setMobileOpen(false), 150)}>
                        </ListItemLink>

                        <Suspense fallback={<ProfileButtonFallback location={location} setMobileOpen={setMobileOpen}/>}>
                            <ProfileButton location={location}
                                           setMobileOpen={setMobileOpen}/>
                        </Suspense>


                    </List>
                </Collapse>
                <Suspense fallback={<CenterLoading />}>
                    <TimetableSmall />
                </Suspense>

            </List>


        </div>
    );


    if(location.pathname === "/onboarding") {
        return (
            <Box component="main" sx={{flexGrow: 1, p: 3}}>
                <CssBaseline />
                {props.children}
            </Box>
        )
    }

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                sx={{
                    width: {sm: `calc(100% - ${drawerWidth}px)`},
                    ml: {sm: `${drawerWidth}px`},
                }}
            >
                <Toolbar>
                    {
                        menuItems.find((i) => i.location === location.pathname) ?
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{mr: 2, display: {sm: 'none'}}}
                            >
                                <MenuRoundedIcon/>
                            </IconButton> : <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={() => history.goBack()}
                            >
                                <ArrowBackRoundedIcon/>
                            </IconButton>
                    }

                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        {
                            menuItems.find((i) => i.location === location.pathname)?.title
                        }
                    </Typography>

                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
            >
                <SwipeableDrawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </SwipeableDrawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'none', sm: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box component="main" sx={{flexGrow: 1, p: location.pathname !== "/timetable" ? 3 : 0}}>
                <Toolbar/>
                {props.children}
            </Box>

        </Box>
    );
}


function ListItemLink(props) {
    const {icon, primary, to, selected, click, sx, secondary} = props;

    const CustomLink = React.useMemo(
        () =>
            React.forwardRef((linkProps, ref) => (
                <Link ref={ref} to={to} {...linkProps} />
            )),
        [to],
    );

    return (
        <li>
            <ListItem button component={CustomLink} selected={selected} sx={sx} onClick={click}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={primary} secondary={secondary}/>
            </ListItem>
        </li>
    );
}

export default NavBar;
