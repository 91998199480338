import React, {useEffect, useState} from 'react';
import {
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import * as yup from "yup";
import {useFormik} from "formik";
import {useFirestore, useFirestoreDocData, useUser} from "reactfire";
import {toast} from "react-hot-toast";
import LoadingButton from "@material-ui/lab/LoadingButton";
import axios from "axios";

const ChangeProfile = props => {
    const [classList, setClassList] = React.useState([]);
    useEffect(async () => {

        const {data} = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/classList`)
        setClassList(data);
    }, []);

    const {data: user} = useUser();
    const firestore = useFirestore();
    const userDetailsRef = firestore
        .collection('users')
        .doc(user.uid);

    let {data} = useFirestoreDocData(
        userDetailsRef
    );

    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        name: yup
            .string('Bitte gebe einen Namen an')
            .required('Bitte gebe einen Namen an'),
        class: yup
            .string('Bitte wähle eine Klasse aus')
            .required('Bitte wähle eine Klasse aus')
    });

    const formik = useFormik({
        initialValues: {
            name: data?.name,
            class: data?.class
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            userDetailsRef.set({"name": values.name, "class": values.class}).then(r => {toast.success('Profil gespeichert.'); setLoading(false)})
        },
    });


    return (
        <div>
            <Typography variant={"h4"} sx={{mb: 3}}>Dein Profil</Typography>
            <form onSubmit={formik.handleSubmit}>

                <TextField label="Name" variant="filled" name="name" fullWidth value={formik.values.name}
                           onChange={formik.handleChange}
                           error={formik.touched.name && Boolean(formik.errors.name)}
                           helperText={formik.touched.name && formik.errors.name}
                />


                <FormControl fullWidth sx={{my: 2}} variant="filled" error={formik.touched.class && Boolean(formik.errors.class)}>
                    <InputLabel id={"classSelect"}>Klasse auswählen</InputLabel>
                    <Select
                        value={formik.values.class}
                        onChange={formik.handleChange}
                        label={"Klasse auswählen"}
                        labelId={"classSelect"}
                        name={"class"}
                    >
                        {
                            classList.map((classEntry) =>  <MenuItem value={classEntry}>{classEntry}</MenuItem>)
                        }


                    </Select>
                    {(formik.touched.class && Boolean(formik.errors.class)) && <FormHelperText>{formik.touched.class && formik.errors.class}</FormHelperText>}
                </FormControl>


                <LoadingButton variant={"contained"} sx={{mt: 3}} type={"submit"} loading={loading}>Speichern</LoadingButton>

            </form>

        </div>
    );
}

export default ChangeProfile
