import React, {useState, useEffect} from 'react';
import {useAuth} from "reactfire";
import * as yup from "yup";
import {useFormik} from "formik";
import {toast} from "react-hot-toast";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import LoadingButton from "@material-ui/lab/LoadingButton";
import firebase from "firebase";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";

const PhoneAuth = () => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [recaptchaVerifier, setRecaptchaVerifier] = useState(null)
    const [confirmationResult, setConfirmationResult] = useState(null)
    const [allowSignIn, setAllowSignIn] = useState(false);
    const [openSMSDialog, setOpenSMSDialog] = useState(false);
    const [SMSCode, setSMSCode] = useState(null);
    const auth = useAuth();

    const validationSchema = yup.object({
        phoneNumber: yup
            .string('Bitte gebe eine Telefonnummer an')
            .required('Bitte gebe eine Telefonnummer an'),
    });

    const formik = useFormik({
        initialValues: {
            phoneNumber: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitLoading(true);
            auth.signInWithPhoneNumber(values.phoneNumber, recaptchaVerifier)
                .then((cr) => {
                    setConfirmationResult(cr)
                    setOpenSMSDialog(true);
                })
                .catch((e) => {
                    if(e.code === "auth/invalid-phone-number") {
                        toast.error('Die angegebene Telefonnummer ist ungültig.')
                        return;

                    }
                    toast.error('Es ist ein Fehler aufgetreten ' + e.code)
                })
                .finally(() => setSubmitLoading(false))
        },
    });

    useEffect(() => {
        const verifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'normal',
            'callback': () => {
                setAllowSignIn(true);
            },
            'expired-callback': () => {
                setAllowSignIn(false);
            }
        });
        verifier.render();
        setRecaptchaVerifier(verifier);

    }, []);

    const signInWithCode = () => {
        if(SMSCode) {
            confirmationResult.confirm(SMSCode).then(() => {
                setOpenSMSDialog(false);
                toast.success('Anmeldung erfolgreich!')
            }).catch((e) => {
                setOpenSMSDialog(true);
                if(e.code === "auth/invalid-verification-code") {
                    toast.error('Der angegebene Code ist falsch.')

                } else {
                    toast.error('Es ist ein Fehler aufgetreten. ' + e.code)
                }

            })
        }
    }

    return (
        <div>
            <Box sx={{textAlign: "center", mt: 4, mb: 5}}>
                <Typography variant={"h4"} sx={{mb: 1}}>
                    Mit Telefonnummer anmelden
                </Typography>
                <Typography variant={"p"}>
                    Melde dich einfach an, indem du deine Telefonnummer angibst.
                </Typography>


            </Box>
            <form onSubmit={formik.handleSubmit}>
                <TextField id="outlined-basic" label="Telefonnummer" variant="outlined" fullWidth name={"phoneNumber"} placeholder={"z.B. +491234567890"}
                           value={formik.values.phoneNumber}
                           onChange={formik.handleChange}
                           error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                           helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                />


                <Box sx={{mt: 2}}>
                    <div id={"recaptcha-container"}/>

                </Box>
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>


                    <LoadingButton variant="contained" disableElevation loading={submitLoading} type={"submit"} sx={{mt: 2}} disabled={!allowSignIn}>
                        SMS Code senden
                    </LoadingButton>
                </Box>
            </form>

            <Dialog open={openSMSDialog} onClose={() => setOpenSMSDialog(false)}>
                <DialogTitle>SMS Code eingeben</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte gebe den SMS Code an, den wir an die angegebene Telefonnummer gesendet haben.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Code"
                        type="number"
                        inputProps={{
                            min: "0",
                            inputmode: "numeric",
                            pattern: "[0-9]{6}"
                        }}

                        value={SMSCode}
                        onChange={(e) => setSMSCode(e.target.value)}

                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSMSDialog(false)}>Abbrechen</Button>
                    <Button onClick={() => signInWithCode()}>Absenden</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PhoneAuth;
