import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import LoadingButton from '@material-ui/lab/LoadingButton';
import {useAuth, useFirestore} from "reactfire";
import * as yup from 'yup';
import {useFormik} from "formik";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import {toast} from "react-hot-toast";

const Login = () => {

    const [loginButtonLoading, setLoginButtonLoading] = useState(false);
    const auth = useAuth();
    const firestore = useFirestore();

    const validationSchema = yup.object({
        email: yup
            .string('Bitte gebe eine E-Mail Adresse an')
            .email('Diese E-Mail Adresse ist nicht gültig')
            .required('Bitte gebe eine E-Mail Adresse an'),
        password: yup
            .string('Bitte gebe ein Passwort an')
            .required('Bitte gebe ein Passwort an'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoginButtonLoading(true);
            auth.signInWithEmailAndPassword(values.email, values.password).then((u) => {
                firestore.collection('users').doc(u.user.uid).get().then((doc) => {
                    if(doc.exists) {
                        let data = doc.data();
                        toast('Willkommen, ' + data.name, {
                            icon: '👋',
                        });

                    } else {
                        toast.success("Deine Anmeldung war erfolgreich!")
                    }
                }).catch(() => toast.success("Deine Anmeldung war erfolgreich!"))
            }).catch((e) => {
                if(e.code === "auth/wrong-password" || e.code === "auth/user-not-found") {
                    toast.error('Benutzername oder Passwort falsch.')
                    return;
                }
                if(e.code === "auth/user-disabled") {
                    toast.error('Dein Konto wurde deaktiviert.')
                    return;
                }
                toast.error('Es ist ein Fehler aufgetreten. ' + e.code)

            })
                .finally(() => setLoginButtonLoading(false))
        },
    });

    return (
        <div>
            <Box sx={{textAlign: "center", mt: 4, mb: 5}}>
                <Typography variant={"h4"} sx={{mb: 1}}>
                    Anmeldung
                </Typography>
                <Typography variant={"p"}>
                    Mit GG Go Konto anmelden.
                </Typography>


            </Box>
            <form onSubmit={formik.handleSubmit}>
                <TextField id="outlined-basic" label="E-Mail Adresse" variant="outlined" fullWidth name={"email"} type={"email"}
                           value={formik.values.email}
                           onChange={formik.handleChange}
                           error={formik.touched.email && Boolean(formik.errors.email)}
                           helperText={formik.touched.email && formik.errors.email}
                />
                <TextField id="outlined-basic" label="Passwort" variant="outlined" fullWidth sx={{mt: 2}}
                           type={"password"} name={"password"}
                           value={formik.values.password}
                           onChange={formik.handleChange}
                           error={formik.touched.password && Boolean(formik.errors.password)}
                           helperText={formik.touched.password && formik.errors.password}/>

                           <Link component={RouterLink} to={"/resetPassword"}>Passwort vergessen?</Link>

                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>

                    <Button component={RouterLink} to="/register">
                            Konto erstellen
                    </Button>


                    <LoadingButton variant="contained" disableElevation loading={loginButtonLoading} type={"submit"}>
                        Anmelden
                    </LoadingButton>
                </Box>
            </form>


            <Divider sx={{my: 3}}>
                <Chip label="ODER"/>
            </Divider>

            <Button fullWidth variant={"contained"} startIcon={<PhoneRoundedIcon/>} disableElevation component={RouterLink} to="/phoneLogin">
                Mit Telefonnummer anmelden
            </Button>
        </div>
    );
};

export default Login;
