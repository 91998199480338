import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

const CenterLoading = () => {
    return (
        <div>
            <Box sx={{display: "flex", height: "80vh", width: "100%", justifyContent: "center", alignItems: "center"}}><CircularProgress/></Box>
        </div>
    );
};

export default CenterLoading;
