import React, {useEffect} from 'react';
import Box from "@material-ui/core/Box";
import {Tab, Tabs, useTheme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import useProfile from "../hooks/useProfile";
import CenterLoading from "../components/CenterLoading";
import {useAuth, useFirestore, useFirestoreDocData, useUser} from "reactfire";


const getSubject = (s) => {
    switch (s) {
        case "Ch":
            return "Chemie"
        case "E":
            return "Englisch";
        case "M":
            return "Mathematik";
        case "F/L":
            return "Französisch/Latein"
        case "Ph":
            return "Physik"
        case "Sp":
            return "Sport"
        case "Ku/Mu":
            return "Kunst/Musik"
        case "G":
            return "Geschichte"
        case "B":
            return "Biologie"
        case "Rel":
            return "Religion"
        case "Pw":
            return "Politik & Wirtschaft";
        default:
            return s;
    }
}



const TimetableSmall = props => {
    const theme = useTheme();
    const [tabValue, setTabValue] = React.useState(0);
    const [loading, setLoading] = React.useState(true);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setTabValue(index);
    };
    const [timetable, setTimetable] = React.useState([]);
    const {data: user} = useUser();
    const firestore = useFirestore();
    const auth = useAuth();
    const userDetailsRef = firestore
        .collection('users')
        .doc(user?.uid);

    const {data: profile} = useFirestoreDocData(
        userDetailsRef
    );
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/timetable/${profile?.class}`)
            .then(res => {

                let day = new Date().getDay();
                if(day !== 0 && day !== 6) {
                    setTimetable(res.data.days[day-1].lessons);
                } else {
                    setTimetable([])
                }

                setLoading(false)
            })
    }, []);
    if(loading) {
        return <CenterLoading />
    }
    return (
        <Box>
            {
                timetable.map((lesson) => {
                    return (
                        <Box sx={{display: "flex", my: 2}}>
                            <div style={{backgroundColor: "#ad2c2c", width: "10px", borderRadius: "25px"}}/>
                            <div>
                                <Typography variant={"h6"} sx={{ml: 2}}>
                                    {getSubject(lesson.subject)}
                                </Typography>
                                {
                                    lesson.teacher && lesson.room &&
                                    <Typography sx={{ml: 2}}>
                                        {lesson.teacher} in {lesson.room}
                                    </Typography>
                                }

                            </div>

                        </Box>

                    )
                })
            }

        </Box>
    );
}



export default TimetableSmall
