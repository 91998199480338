import React from 'react';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import Box from "@material-ui/core/Box";
import {motion} from "framer-motion";
import {Typography} from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import Button from "@material-ui/core/Button";

const OnBoarding = props => {
    const itemAnimation = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };
    return (
        <Box>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", w: "100%", flexDirection: "column", textAlign: "center", height: "100%"}}>
                <motion.div animate={{opacity: 1}} initial={{opacity: 0}} transition={{delay: 0.3, duration: .8}} style={{display: "flex", justifyContent: "center"}}>
                    <lottie-player
                        autoplay
                        loop
                        mode="normal"
                        src="https://assets8.lottiefiles.com/packages/lf20_K7aZUG.json"
                        style={{width: "250px"}}
                    />
                </motion.div>

                <Box sx={{mt: 3}}>
                    <motion.div variants={
                        {
                            visible: {
                                transition: {
                                    delayChildren: 0.8,
                                    staggerChildren: 0.2
                                }
                            }
                        }
                    } initial="hidden" animate="visible">
                        <motion.div variants={itemAnimation} transition={{ ease: "easeOut", duration: 1 }}>
                            <Typography variant={"h4"} >Willkommen zur neuen GG-Go App</Typography>
                        </motion.div>
                        <Box sx={{mt: 3}}>
                            <motion.div variants={itemAnimation} transition={{ ease: "easeOut", duration: 1 }}>
                                <Typography variant={"p"} >Wir haben einige neue Funktionen für dich vorbereitet.</Typography>
                            </motion.div>
                        </Box>
                        <Box sx={{textAlign: "left", ml: 1, mt: 4}}>
                            <motion.div variants={
                                {
                                    visible: {
                                        transition: {
                                            delayChildren: 1.7,
                                            staggerChildren: 0.2
                                        }
                                    }
                                }
                            } initial="hidden" animate="visible">
                                {
                                    ["Verbessertes und übersichtlicheres Design", "Eintragen von Noten und Hausaufgaben", "Synchronisation auf alle deine Geräte"].map((e, idx) => {
                                        return (
                                            <motion.div variants={itemAnimation} transition={{ ease: "easeOut", duration: 1 }} key={idx}>
                                                <Box sx={{display: "flex", alignItems: "center", my: 1}}>
                                                    <CheckRoundedIcon color={"primary"} sx={{mr: 1}}/>
                                                    <span>{e}</span>
                                                </Box>
                                            </motion.div>
                                        )
                                    })
                                }
                            </motion.div>
                        </Box>


                    </motion.div>
                </Box>

                <motion.div animate={{opacity: 1}} initial={{opacity: 0}} transition={{delay: 2.5, duration: .8}}>
                    <Button variant="contained" startIcon={<AutoAwesomeRoundedIcon />} sx={{mt: 4}}>
                        Los geht's
                    </Button>
                </motion.div>


            </Box>

        </Box>
    );
}

export default OnBoarding
