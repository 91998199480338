import React from "react";
import Box from "@material-ui/core/Box";
import {Paper} from "@material-ui/core";
import Substitutions from "./Substitutions";

const Dashboard = props => {
    return (
        <Box>
            <Paper variant="outlined" sx={{p: 3}}>
                <Substitutions />
            </Paper>
        </Box>
    )
};

export default Dashboard
