import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import {useFormik} from "formik";
import * as yup from "yup";
import LoadingButton from "@material-ui/lab/LoadingButton";
import {useAuth, useFirestore} from "reactfire";
const CreateAccount = () => {

    const auth = useAuth();
    const firestore = useFirestore();

    const [submitLoading, setSubmitLoading] = useState(false);
    const validationSchema = yup.object({
        name: yup
            .string('Bitte gebe einen Namen an')
            .required('Bitte gebe einen Namen an')
            .min(2, 'Der Name muss mindestens 2 Zeichen lang sein'),
        email: yup
            .string('Bitte gebe eine E-Mail Adresse an')
            .email('Diese E-Mail Adresse ist nicht gültig')
            .required('Bitte gebe eine E-Mail Adresse an'),
        password: yup
            .string('Bitte gebe ein Passwort an')
            .min(6, 'Das Passwort muss mindestens 6 Zeichen lang sein')
            .required('Bitte gebe ein Passwort an'),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Die Passwörter stimmen nicht überein')
            .required('Bitte gebe das Passwort erneut an')
    });



    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitLoading(true);
            auth.createUserWithEmailAndPassword(values.email, values.password).then((r) => {
                firestore.collection('users').doc(r.user.uid).set({name: values.name}).then(() => {
                    setSubmitLoading(false)
                })
            }).catch((e) => {
                alert(e.code)
            })
                .finally(() => setSubmitLoading(false))
        },
    });

    return (
        <div>
            <Box sx={{textAlign: "center", mt: 4, mb: 5}}>
                <Typography variant={"h4"} sx={{mb: 1}}>
                    Registrieren
                </Typography>
                <Typography variant={"p"}>
                    Erstelle ein neues GG Go Konto.
                </Typography>


            </Box>

            <form onSubmit={formik.handleSubmit}>
                <TextField id="outlined-basic" label="Dein Name" variant="outlined" fullWidth sx={{mb: 2}}
                           type={"text"} name={"name"}
                           value={formik.values.name}
                           onChange={formik.handleChange}
                           error={formik.touched.name && Boolean(formik.errors.name)}
                           helperText={formik.touched.name && formik.errors.name}/>

                <TextField id="outlined-basic" label="E-Mail Adresse" variant="outlined" fullWidth
                           type={"email"} name={"email"}
                           value={formik.values.email}
                           onChange={formik.handleChange}
                           error={formik.touched.email && Boolean(formik.errors.email)}
                           helperText={formik.touched.email && formik.errors.email}/>

                <TextField id="outlined-basic" label="Passwort" variant="outlined" fullWidth sx={{my: 2}}
                           type={"password"} name={"password"}
                           value={formik.values.password}
                           onChange={formik.handleChange}
                           error={formik.touched.password && Boolean(formik.errors.password)}
                           helperText={formik.touched.password && formik.errors.password}/>

                <TextField id="outlined-basic" label="Passwort wiederholen" variant="outlined" fullWidth
                           type={"password"} name={"confirmPassword"}
                           value={formik.values.confirmPassword}
                           onChange={formik.handleChange}
                           error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                           helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}/>

                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>



                    <LoadingButton variant="contained" disableElevation type={"submit"} loading={submitLoading} sx={{mt: 2}}>
                        Registrieren
                    </LoadingButton>
                </Box>
            </form>

        </div>
    );
};

export default CreateAccount;
