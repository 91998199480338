import React, {useState} from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import LoadingButton from "@material-ui/lab/LoadingButton";
import * as yup from "yup";
import {useFormik} from "formik";
import {useAuth} from "reactfire";
import {toast} from "react-hot-toast";

const ResetPassword = () => {

    const [submitLoading, setSubmitLoading] = useState(false);
    const auth = useAuth();

    const validationSchema = yup.object({
        email: yup
            .string('Bitte gebe eine E-Mail Adresse an')
            .email('Diese E-Mail Adresse ist nicht gültig')
            .required('Bitte gebe eine E-Mail Adresse an'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitLoading(true);
            auth.sendPasswordResetEmail(values.email).then((u) => {
                toast.success('Eine E-Mail um dein Passwort zurückzusetzten wurde gesendet.')
            }).catch((e) => {
                if(e.code === "auth/user-not-found") {
                    toast.error('Für die angegebene E-Mail Adresse wurde kein Konto gefunden.')
                } else  {
                    toast.error('Es ist ein Fehler aufgetreten: ' + e.code)
                }
            })
                .finally(() => setSubmitLoading(false))
        },
    });

    return (
        <div>
            <Box sx={{textAlign: "center", mt: 4, mb: 5}}>
                <Typography variant={"h4"} sx={{mb: 1}}>
                    Passwort vergessen?
                </Typography>
                <Typography variant={"p"}>
                    Setze dein Passwort zurück, wenn du es vergessen hast.
                </Typography>


            </Box>
            <form onSubmit={formik.handleSubmit}>
                <TextField id="outlined-basic" label="E-Mail Adresse" variant="outlined" fullWidth name={"email"}
                           value={formik.values.email}
                           onChange={formik.handleChange}
                           error={formik.touched.email && Boolean(formik.errors.email)}
                           helperText={formik.touched.email && formik.errors.email}
                />
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>


                    <LoadingButton variant="contained" disableElevation loading={submitLoading} type={"submit"} sx={{mt: 2}}>
                        Passwort zurücksetzten
                    </LoadingButton>
                </Box>
            </form>
        </div>
    );
};

export default ResetPassword;
