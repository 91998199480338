import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import {Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';
import axios from "axios";

const Settings = props => {


    return (
        <div>
            <List
                sx={{width: '100%', bgcolor: 'background.paper'}}
            >

                <ListSubheader>Allgemeine Einstellungen</ListSubheader>
                <ListItem disablePadding>
                    <ListItemButton component={RouterLink} to={"/changeProfile"}>
                        <ListItemIcon>
                            <PersonRoundedIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Persönliche Einstellungen"/>
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListSubheader>Weitere Informationen</ListSubheader>
                <ListItem disablePadding>
                    <ListItemButton component="a" href={"/3rd-party-licenses.txt"}>
                        <ListItemIcon>
                            <GavelRoundedIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Lizenzen"/>
                    </ListItemButton>
                </ListItem>
            </List>

        </div>
    )
};

export default Settings
