import './App.css';
import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {createTheme, useMediaQuery} from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import indigo from "@material-ui/core/colors/indigo";
import {ThemeProvider} from "@material-ui/core/styles";
import NavBar from "./components/NavBar";
import "@fontsource/roboto"
import './css/routerFade.css'
import Routes from "./Routes";
import {preloadAuth, preloadFirestore, useFirebaseApp} from "reactfire";
import {Toaster} from "react-hot-toast";

const preloadSDKs = (firebaseApp) => {
    return Promise.all([
        preloadFirestore({ firebaseApp }),
        preloadAuth({ firebaseApp })
    ]);
};

function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const firebaseApp = useFirebaseApp();

    // Kick off fetches for SDKs and data that
    // we know our components will eventually need.
    preloadSDKs(firebaseApp);


    //create material ui theme
    const theme = createTheme({
        palette: {
            primary: red,
            secondary: indigo,
            mode: prefersDarkMode ? 'dark' : 'light',
        },
    });

    return (
        <div>

                <ThemeProvider theme={theme}>
                    <Toaster position="top-center"/>
                    <Router>
                            <NavBar>
                                <Routes/>
                            </NavBar>

                    </Router>
                </ThemeProvider>

        </div>
    );
}

export default App;
