import React, {useEffect} from 'react';
import Box from "@material-ui/core/Box";
import SwipeableViews from 'react-swipeable-views';
import {Tab, Tabs, useTheme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import useProfile from "../hooks/useProfile";
import CenterLoading from "../components/CenterLoading";

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


const getSubject = (s) => {
    switch (s) {
        case "Ch":
            return "Chemie"
        case "E":
            return "Englisch";
        case "M":
            return "Mathematik";
        case "F/L":
            return "Französisch/Latein"
        case "Ph":
            return "Physik"
        case "Sp":
            return "Sport"
        case "Ku/Mu":
            return "Kunst/Musik"
        case "G":
            return "Geschichte"
        case "B":
            return "Biologie"
        case "Rel":
            return "Religion"
        case "Pw":
            return "Politik & Wirtschaft";
        default:
            return s;
    }
}



const Timetable = props => {
    const theme = useTheme();
    const [tabValue, setTabValue] = React.useState(0);
    const [loading, setLoading] = React.useState(true);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setTabValue(index);
    };
    const [timetable, setTimetable] = React.useState([]);
    const {profile} = useProfile();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/timetable/${profile?.class}`)
            .then(res => {
                setTimetable(res.data);
                let day = new Date().getDay();
                if(day !== 0 && day !== 6) {
                    setTabValue(day-1)
                }

                setLoading(false)
            })
    }, []);
    if(loading) {
        return <CenterLoading />
    }
    return (
        <Box sx={{maxWidth: "100vw"}}>

            <Box sx={{maxWidth: '100%', overflow: "hidden"}}>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor="primary"
                    variant="scrollable"
                    sx={{
                        "& .MuiTab-root": {
                            flexGrow: 1,
                            minWidth: 120
                        }
                    }}
                >
                    <Tab label="Mo" {...a11yProps(0)} />
                    <Tab label="Di" {...a11yProps(1)} />
                    <Tab label="Mi" {...a11yProps(2)} />
                    <Tab label="Do" {...a11yProps(3)} />
                    <Tab label="Fr" {...a11yProps(4)} />
                </Tabs>
            </Box>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tabValue}
                onChangeIndex={handleChangeIndex}

            >
                {
                    [...Array(timetable.days.length).keys()].map((i) => {
                        return (
                            <TabPanel value={tabValue} index={i} dir={theme.direction}>
                                {
                                    timetable.days[i].lessons.map((lesson) => {
                                        return (
                                            <Box sx={{display: "flex", my: 2}}>
                                                <div style={{backgroundColor: "#ad2c2c", height: "80px", width: "15px", borderRadius: "25px"}}/>
                                                <div>
                                                    <Typography variant={"h5"} sx={{ml: 2}}>
                                                        {getSubject(lesson.subject)}
                                                    </Typography>
                                                    {
                                                        lesson.teacher && lesson.room &&
                                                        <Typography sx={{ml: 2}}>
                                                            {lesson.teacher} in {lesson.room}
                                                        </Typography>
                                                    }

                                                </div>

                                            </Box>

                                        )
                                    })
                                }


                            </TabPanel>
                        )
                    })
                }



            </SwipeableViews>
            <Box>

            </Box>

        </Box>
    );
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default Timetable
