import React, {Suspense} from 'react';
import Login from "../components/login/Login";
import {AuthCheck, useAuth, useFirestore, useFirestoreDocData, useSigninCheck, useUser} from "reactfire";
import CenterLoading from "../components/CenterLoading";
import Typography from "@material-ui/core/Typography";
import {Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader} from "@material-ui/core";
import {toast} from "react-hot-toast";
import {Link as RouterLink} from "react-router-dom";
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import {Paper} from "@mui/material";

const Profile = () => {


    return (
        <div>
            <Suspense fallback={<CenterLoading/>}>
                <AuthCheck fallback={<Login/>}>
                    <ProfileData/>
                </AuthCheck>
            </Suspense>


        </div>

    );
};


const ProfileData = () => {
    const {data: user} = useUser();
    const firestore = useFirestore();
    const auth = useAuth();
    const userDetailsRef = firestore
        .collection('users')
        .doc(user?.uid);

    let {data} = useFirestoreDocData(
        userDetailsRef
    );

    const logOut = () => {
        auth.signOut().then(() => {
            toast.success('Abmeldung erfolgreich')
        });
    }

    const {data: signInCheckResult} = useSigninCheck();
    return (
        <div>
            <Paper sx={{display: "flex", alignItems: "center", p: 2, mb: 1}}>
                <Avatar src={signInCheckResult.signedIn ? (signInCheckResult.user.email ? `https://avatars.dicebear.com/api/identicon/${signInCheckResult.user.email}.svg?b=%23f9f9f9` : `https://avatars.dicebear.com/api/identicon/${signInCheckResult.user.phoneNumber}.svg?b=%23f9f9f9`) : "#"}/>
                <Typography variant={"h5"} sx={{ml: 2, fontWeight: "bold"}}>Hallo, {data?.name}</Typography>
            </Paper>

            <List
                sx={{width: '100%', bgcolor: 'background.paper'}}
            >
                <ListSubheader>Dein Konto</ListSubheader>
                <ListItem disablePadding>
                    <ListItemButton component={RouterLink} to={"/changeProfile"}>
                        <ListItemIcon>
                            <PersonRoundedIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Profil bearbeiten"/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => logOut()}>
                        <ListItemIcon>
                            <LogoutRoundedIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Abmelden"/>
                    </ListItemButton>
                </ListItem>

            </List>


        </div>

    )
}


export default Profile;
