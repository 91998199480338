import React, {Suspense} from 'react';
import {Route, Switch, useLocation} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import Substitutions from "./pages/Substitutions";
import Profile from "./pages/Profile";
import CreateAccount from "./pages/CreateAccount";
import ResetPassword from "./pages/ResetPassword";
import CenterLoading from "./components/CenterLoading";
import PhoneAuth from "./components/login/PhoneAuth";
import ChangeProfile from "./pages/ChangeProfile";
import OnBoarding from "./pages/OnBoarding";
import Timetable from "./pages/Timetable";


function Routes(props) {
    const location = useLocation();

    return (
        <div>
            <Switch location={location}>


                <Route exact path="/">
                    <Dashboard/>
                </Route>
                <Route path="/settings">
                    <Settings/>
                </Route>
                <Route path="/substitutions">
                    <Substitutions/>
                </Route>
                <Route path="/timetable">
                    <Suspense fallback={<CenterLoading/>}>
                        <Timetable/>
                    </Suspense>
                </Route>
                <Route path="/profile">
                    <Suspense fallback={<CenterLoading/>}>
                        <Profile/>
                    </Suspense>
                </Route>
                <Route path="/changeProfile">
                    <Suspense fallback={<CenterLoading/>}>
                        <ChangeProfile/>
                    </Suspense>
                </Route>
                <Route path="/register">
                    <Suspense fallback={<CenterLoading/>}>
                        <CreateAccount/>
                    </Suspense>
                </Route>
                <Route path="/resetPassword">
                    <Suspense fallback={<CenterLoading/>}>
                        <ResetPassword/>
                    </Suspense>
                </Route>
                <Route path="/phoneLogin">
                    <Suspense fallback={<CenterLoading/>}>
                        <PhoneAuth/>
                    </Suspense>
                </Route>


                <Route path="/onboarding">
                    <Suspense fallback={<CenterLoading/>}>
                        <OnBoarding/>
                    </Suspense>
                </Route>
            </Switch>
        </div>
    );
}

export default Routes;
